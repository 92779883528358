import Avatar1 from '../../assets/avatar1.jpg';
import Avatar2 from '../../assets/avatar2.jpg';
import Avatar3 from '../../assets/avatar3.jpg';
import Avatar4 from '../../assets/avatar4.jpg';
import Avatar5 from '../../assets/avatar5.jpg';
import Avatar6 from '../../assets/avatar6.jpg';
import Avatar7 from '../../assets/avatar7.jpg';

const data = [
  {
    id: 1,
    quote:
      "It's truly amazing how you did exactly what I want with the little information I gave. You'll be my go-to developer from today. Highly recommend!",
    avatar: Avatar1,
    name: 'Nana Ama McBrown',
    profession: 'Actress',
  },
  {
    id: 2,
    quote:
      'Good job, Travis! Right on time! I really like how the project turned out, and my customers like it as well. It was fun collaborating with you.',
    avatar: Avatar2,
    name: 'Ernest Achiever',
    profession: 'Web Developer',
  },
  {
    id: 3,
    quote: 'Impressive work, buddy. Highly recommend!',
    avatar: Avatar3,
    name: 'Daniel Vinyo',
    profession: 'Graphic Designer',
  },
  {
    id: 4,
    quote:
      "Wow. You finished earlier than you said. Everything's modern and beautifully done to the T. Great profession!",
    avatar: Avatar4,
    name: 'Diana Ayi',
    profession: 'Singer',
  },
  {
    id: 5,
    quote: 'I like how the app turned out. You are incredible, Travis.',
    avatar: Avatar5,
    name: 'Vivian Mensah',
    profession: 'Street Photographer',
  },
  {
    id: 6,
    quote: 'Best developer in the world!',
    avatar: Avatar6,
    name: 'Hajia Bintu',
    profession: 'Brand Influencer',
  },
  {
    id: 7,
    quote: 'Keep doing great stuff, dear! Nicely executed!',
    avatar: Avatar7,
    name: 'Nicki Nyarko',
    profession: 'Makeup Artist',
  },
];

export default data;
